import CryptoJS from 'crypto-js';

const SECRETKEY = process.env.SECRETKEY || 'secretPassword';

export const CryptoEncrypt = (data) => {
    return CryptoJS.AES.encrypt(data, SECRETKEY).toString();
};
export const CryptoDecrypt = (data) => {
    const decryptedBytes = CryptoJS.AES.decrypt(data, SECRETKEY);
    return decryptedBytes.toString(CryptoJS.enc.Utf8);
};