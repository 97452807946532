import { createSlice } from '@reduxjs/toolkit'
import { CryptoDecrypt } from '../../utils/globalFunctionUtil';
export const userDataSlice = createSlice({
    name: 'user',
    initialState: {
        account: sessionStorage.getItem("account") ? JSON.parse(CryptoDecrypt(sessionStorage.getItem("account"))): "", 
        role: sessionStorage.getItem("role") ? JSON.parse(CryptoDecrypt(sessionStorage.getItem("role"))): "",  
        reset_status: sessionStorage.getItem("reset_status") ? CryptoDecrypt(sessionStorage.getItem("reset_status")): 0, 
        token : sessionStorage.getItem("token") ? CryptoDecrypt(sessionStorage.getItem("token")): "",   
        privileges : sessionStorage.getItem("privileges") ? JSON.parse(CryptoDecrypt(sessionStorage.getItem("privileges"))): {},   
        user : sessionStorage.getItem("user") ? JSON.parse(CryptoDecrypt(sessionStorage.getItem("user"))): {},   
    },
    reducers: { 
        setUserData: (state) => {
            state.account = sessionStorage.getItem("account") ? JSON.parse(CryptoDecrypt(sessionStorage.getItem("account"))): "";
            state.role= sessionStorage.getItem("role") ? JSON.parse(CryptoDecrypt(sessionStorage.getItem("role"))): "" ;
            state.reset_status= sessionStorage.getItem("reset_status") ? CryptoDecrypt(sessionStorage.getItem("reset_status")): 0;
            state.token = sessionStorage.getItem("token") ? CryptoDecrypt(sessionStorage.getItem("token")): "";
            state.privileges = sessionStorage.getItem("privileges") ? JSON.parse(CryptoDecrypt(sessionStorage.getItem("privileges"))): {}   ;
            state.user = sessionStorage.getItem("user") ? JSON.parse(CryptoDecrypt(sessionStorage.getItem("user"))): {}  ;
        },
        clearUserData: (state) => {
            state.account = ""
            state.role = ""
            state.reset_status = ""
            state.token = ""
            state.privileges = {}
            state.user = {}
        },
    }
})

export const { setUserData, clearUserData } = userDataSlice.actions

export default userDataSlice.reducer