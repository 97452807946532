import axios from "axios"

import { CryptoDecrypt } from '../utils/globalFunctionUtil';

const checkAuth = () => {
    const TOKEN         = sessionStorage.getItem("token") ? CryptoDecrypt(sessionStorage.getItem("token")): "";
    const USER_ID       = sessionStorage.getItem("user_id") ? CryptoDecrypt(sessionStorage.getItem("user_id")): "";
    const ROLE          = sessionStorage.getItem("role") ? CryptoDecrypt(sessionStorage.getItem("role")): "";
    const USERNAME      = sessionStorage.getItem("username") ? CryptoDecrypt(sessionStorage.getItem("username")): "";
    const EMAIL         = sessionStorage.getItem("email") ? CryptoDecrypt(sessionStorage.getItem("email")): "";
    const ACCOUNT       = sessionStorage.getItem("account") ? CryptoDecrypt(sessionStorage.getItem("account")): "";
    const privileges    = sessionStorage.getItem("privileges") ? JSON.parse(CryptoDecrypt(sessionStorage.getItem("privileges"))): "";

    const RESET_STATUS  = sessionStorage.getItem("reset_status");

    const PUBLIC_ROUTES = ["login", "forgot-password", "register", "documentation", "reset-password", "change-password"];
    const isPublicPage  = PUBLIC_ROUTES.some( r => window.location.href.includes(r))
    const path     = window.location.pathname; 
    const parts    = path.split('/'); 
    let ModuleName = "";
    if(parts[2]){
      ModuleName = parts[2].charAt(0).toUpperCase() + parts[2].slice(1);
    }
    if(RESET_STATUS === "1"  && !isPublicPage){
      window.location.href = '/app/change-password'
      //return;
    }
    
    if(!TOKEN && !EMAIL && !ACCOUNT && !isPublicPage){
      window.location.href = '/login'
      //return;
    } else
    if(ModuleName !== "404" && ModuleName !== "" && ModuleName !== "undefined" && !(privileges[ModuleName] && privileges[ModuleName].read) && !isPublicPage){
      window.location.href = '/app/404'
      //return;
    } else {
      //axios.defaults.headers.common['Access-Control-Max-Age'] = `86400`
      if(TOKEN){
         axios.defaults.headers.common['Authorization'] = `Bearer ${TOKEN}`
      }
      if(USERNAME){
         axios.defaults.headers.common['username']      = `${USERNAME}`
      }
      if(EMAIL){
         axios.defaults.headers.common['email']         = `${EMAIL}`
      }
      if(ACCOUNT){
         axios.defaults.headers.common['account']       = `${ACCOUNT}`
      }
      if(ROLE){
         axios.defaults.headers.common['role']          = `${ROLE}`
      }
      //axios.defaults.headers.common['user_id']       = `${USER_ID}`
      axios.defaults.headers.common['Content-Type']  = 'application/json'
      axios.interceptors.request.use(function (config) {
          if (USER_ID) {
            config.headers['userid'] = `${USER_ID}`;
          }
          return config
      }, function (error) {
          return Promise.reject(error);
      });
      axios.interceptors.response.use(function (response) {
          // UPDATE: Add this code to hide global loading indicator
          //document.body.classList.remove('loading-indicator');
          return response;
      }, function (error) {
          //document.body.classList.remove('loading-indicator');
          return Promise.reject(error);
      });
      return TOKEN
    }
}
export default checkAuth