import React, { lazy, useEffect } from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { themeChange } from 'theme-change'
import checkAuth from './app/auth';
import initializeApp from './app/init';
const Layout         = lazy(() => import('./containers/Layout'))
const ScrollToTop    = lazy(() => import('./containers/ScrollToTop'))
const Login          = lazy(() => import('./pages/Login'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const ResetPassword  = lazy(() => import('./pages/ResetPassword'))
const Register       = lazy(() => import('./pages/Register'))
const Documentation  = lazy(() => import('./pages/Documentation'))
initializeApp()
const token = checkAuth()
function App() {
    useEffect(() => {
      themeChange(false)
    }, [])
    return (
      <>
        <Router>
            <ScrollToTop/>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/register" element={<Register />} />
                <Route path="/documentation" element={<Documentation />} />
                {/* Place new routes over this */}
                <Route path="/app/*" element={<Layout />} />
                <Route path="*" element={<Navigate to={token ? "/app/dashboard  " : "/login"} replace />}/>
            </Routes>
        </Router>
      </>
    )
}
export default App
